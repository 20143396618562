<template>
    <b-card>
        <b-overlay
            variant="white"
            :show="loading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
        >
            <p class="mb-1">
                Connect Abracadalo with your Adalo app to enable advanced features like Push Notifications!
                Learn how to get your Adalo API key <a href="https://help.adalo.com/integrations/the-adalo-api" target="_blank">here</a>.
                You can get your app's ID from the URL while editing your app in Adalo.
            </p>
            
            <b-form @submit.prevent="save">
                <b-row>
                    <b-col cols="12" sm="6">
                        <b-form-group label="API Key">
                            <b-form-input v-model="adalo.apiKey" type="password"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <b-form-group label="App ID">
                            <b-form-input v-model="adalo.appId" placeholder="e973bf3q-067a-4d3d-8fae-04019d425f25"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-button type="submit" variant="primary" class="float-right">Save</b-button>
            </b-form>
            <small v-if="!isValid" class="text-danger pr-4">The app ID you entered looks invalid. Please check again or contact us if you believe this is an error.</small>
        </b-overlay>
    </b-card>
</template>

<script>
import { BOverlay, BCard, BForm, BFormGroup, BButton, BFormInput, BRow, BCol } from "bootstrap-vue";
import { doc, updateDoc } from "firebase/firestore";
import { mapGetters } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {
        BCard,
        BForm, 
        BFormGroup, 
        BButton, 
        BFormInput,
        BRow,
        BCol,
        BOverlay
    },
    props: {
        value: Object
    },
    data: () => ({
        loading: true,
        isValid: true,
        adalo: {
            apiKey: "",
            appId: ""
        }
    }),
    computed: {
        ...mapGetters({
			user: "auth/user"
		})
    },
    watch: {
        value() {
            this.loading = false;
            this.adalo = this.value.adalo || {
                apiKey: "",
                appId: ""
            }
        }
    },
    mounted() {
        if (this.value !== null) {
            this.loading = false;
            this.adalo = this.value.adalo || {
                apiKey: "",
                appId: ""
            }
        }
    },
    methods: {
        save() {
            const uuidRegex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/)
            if (!uuidRegex.test(this.adalo.appId)) {
                this.isValid = false;
                return;
            }

            this.isValid = true;
            this.loading = true;
            updateDoc(
                this.$route.params.app ?
                doc(this.$firestore, "users", this.user.data.uid, "apps", this.$route.params.app) :
                doc(this.$firestore, "users", this.user.data.uid), {
                adalo: this.adalo
            }).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Adalo settings saved.',
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                });
            }).catch(err => {
                console.error(err);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Oops, something went wrong!',
                        icon: 'EditIcon',
                        variant: 'danger',
                    },
                });
            }).finally(() => {
                this.loading = false;
            })
        }
    }
    
}
</script>

<style lang="scss" scoped>

</style>