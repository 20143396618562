<template>
    <div :key="$route.params.app">
        <AppSelect v-if="userPlan === 'Master Wizard'" v-model="app" />
        <h3>Connect Your Adalo App</h3>
        <hr>
        <AdaloSettings :value="appData" />
        <h3>E-Mail API Setup</h3>
        <hr>
        <b-row>
            <b-col cols="12" md="6">
                <EmailServerConnection :value="appData" />
            </b-col>
            <b-col cols="12" md="6">
                <EmailContentSettings :value="appData" />
            </b-col>
        </b-row>
        <h3>Connect Twilio (SMS)</h3>
        <hr>
        <TwilioSettings :value="appData" />
        <hr>
        <b-button 
            variant="danger" 
            v-if="app && app != 'default'"
            @click="deleteConfirm()"
        >Delete App</b-button>
    </div>
</template>

<script>
import EmailServerConnection from "./components/EmailServerConnection.vue";
import EmailContentSettings from "./components/EmailContentSettings.vue";
import TwilioSettings from "./components/TwilioSettings.vue";
import AdaloSettings from "./components/AdaloSettings.vue";
import AppSelect from "./components/AppSelect.vue";
import { BRow, BCol, BButton } from "bootstrap-vue";
import { doc, getDoc, deleteDoc } from '@firebase/firestore';
import { mapGetters } from "vuex";
import getUserPlan from "../stripe/getUserPlan";

export default {
    components: {
        EmailServerConnection,
        EmailContentSettings,
        TwilioSettings,
        AdaloSettings,
        AppSelect,
        BRow,
        BCol,
        BButton
    },
    data: () => ({
        app: null,
        appData: null,
        userPlan: 'Novice'
    }),
    created() {
        this.app = this.$route.params.app || null;
        getUserPlan().then(res => {
            this.userPlan = res;
        })
    },
    computed: {
        ...mapGetters({
			user: "auth/user"
		})
    },
    watch: {
        "$route.params.app"() { this.getAppData() },
        "user.data.uid"() { this.getAppData() },
        app() {
            this.$router.push('/settings/' + (this.app || ''));
        }
    },
    mounted() {
        if (this.user.data) this.getAppData();
        console.log(this.user)
    },
    methods: {
        async getAppData() {
            if (this.$route.params.app) {
                const app = await getDoc(doc(this.$firestore, 
                    "users", this.user.data.uid, 
                    "apps", this.$route.params.app
                ));
                this.appData = app.data();
            } else {
                this.appData = this.user.data;
            }
        },

		deleteConfirm() {
			this.$swal({
				title: 'Are you sure?',
				text: 'Requests using this app will not work anymore. This also affects users who use a version of your application that works with this app! This action cannot be undone.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (!result.value) return;

				deleteDoc(doc(this.$firestore, "users", this.user.data.uid, "apps", this.app)).then(() => {
					this.$swal({
						icon: 'success',
						title: 'Deleted!',
						text: 'The app has been deleted.',
						customClass: {
							confirmButton: 'btn btn-success',
						},
					});
                    this.app = null;
				}).catch(e => {
                    console.error(e);

                    this.$swal({
                        icon: 'danger',
                        title: 'Oops, something went wrong!',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                })
			})
		}
    }
}
</script>

<style lang="scss" scoped>

</style>