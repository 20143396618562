<template>
    <b-card title="General (Defaults)">
        <b-overlay
            variant="white"
            :show="loading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
        >
            <p class="mb-1">
                Those values will be shown as the sender information on the mails received by your clients.
            </p>
            <p class="mb-2">
                You can have varying senders (e.g. <em>noreply@company.com</em> and <em>contact@company.com</em>)
                by using the parameters on the <a href="https://docs.abracadalo.com/email-api#send" target="_blank">E-Mail Send</a> request.
            </p>
            
            <b-form @submit.prevent="save">
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="Sender Name">
                            <b-form-input
                                v-model="mail.senderName"
                                placeholder="John Doe"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Sender E-Mail">
                            <b-form-input
                                v-model="mail.senderEmail"
                                type="email"
                                placeholder="noreply@yourcompany.com"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-button type="submit" variant="primary" class="float-right">Save</b-button>
            </b-form>
        </b-overlay>
    </b-card>
</template>

<script>
import { BOverlay, BCard, BForm, BFormGroup, BButton, BFormInput, BRow, BCol } from "bootstrap-vue";
import { doc, updateDoc } from "firebase/firestore";
import { mapGetters } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {
        BCard,
        BForm, 
        BFormGroup, 
        BButton, 
        BFormInput,
        BRow,
        BCol,
        BOverlay
    },
    props: {
        value: Object
    },
    data: () => ({
        loading: true,
        isValid: null,
        mail: {
            senderName: "",
            senderEmail: ""
        }
    }),
	computed: {
		...mapGetters({
			user: "auth/user"
		})
	},
    mounted() {
        if (this.value !== null) {
            this.loading = false;
            this.mail = this.value.mail || {
                senderName: "",
                senderEmail: ""
            }
        }
    },
	watch: {
		value() {
            this.loading = false;
            this.mail = this.value.mail || {
                senderName: "",
                senderEmail: ""
            }
		}
	},
    methods: {
        save() {
            this.isValid = null;
            this.loading = true;
            updateDoc(
                this.$route.params.app ?
                doc(this.$firestore, "users", this.user.data.uid, "apps", this.$route.params.app) :
                doc(this.$firestore, "users", this.user.data.uid), {
                mail: {
                    ...this.user.data.mail,
                    senderName: this.mail.senderName,
                    senderEmail: this.mail.senderEmail
                }
            }).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Email settings saved.',
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                });
            }).catch(err => {
                console.error(err);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Oops, something went wrong!',
                        icon: 'EditIcon',
                        variant: 'danger',
                    },
                });
            }).finally(() => {
                this.loading = false;
            })
        }
    }
    
}
</script>

<style lang="scss" scoped>

</style>